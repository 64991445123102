<template>
  <nav
    class="nhsuk-breadcrumb"
    aria-label="Breadcrumb">
    <div
      class="nhsuk-width-container"
      :class="{
        'nhsuk-breadcrumb-container--is-center-text': stack.length === 1,
      }"
    >
      <ol class="nhsuk-breadcrumb__list">
        <template v-for="(route, i) in stack">
          <li
            v-if="stackNames[i].header !== ' '"
            :key="'route' + i"
            class="nhsuk-breadcrumb__item"
            :class="{
              'nhsuk-breadcrumb__item--no-visible': !stackNames[i],
            }"
          >
            <h1
              class="nhsuk-breadcrumb__button"
              v-if="stackNames[i] && stack.length === 1">
              {{ capitalizeText(stackNames[i].header) }}
            </h1>
            <button
              v-else
              data-testid="navigation-click"
              class="nhsuk-breadcrumb__button nhsuk-breadcrumb__link"
              @click="navigationClick(route)"
            >
              <span>
                {{ capitalizeText(stackNames[i].header) }}
              </span>
            </button>
          </li>
        </template>
      </ol>
    </div>
  </nav>
</template>

<script>
  import { capitalize } from 'lodash'
  import { mapState } from 'vuex'

  export default {
    mounted() {
      this.addRouteToStack(this.$route)
    },
    watch: {
      $route(to, from) {
        const isSearchResultsParent = from.name === this.searchResultsName
        this.addRouteToStack(to, isSearchResultsParent)
      },
      title: {
        handler(newTitle) {
          if (!newTitle) return
          this.stackNames.pop()
          this.stackNames.push({ name: newTitle, header: newTitle })
        },
        deep: true,
      },
      category: {
        handler(newCategory) {
          if (!newCategory) return
          const routeCategoryIndex = this.stack.findIndex(route => this.isAllowedCategory(route))
          // We allow certain routes with previous breadcrumb to override the header name,
          // this for instance happens when directly clicking on a reading room article,
          // to display in the previous breadcrumb section the reading room category name
          // or when directly clicking on edit goal, to display in the previous breadcrumb
          // section the goal name.
          if (routeCategoryIndex !== -1) {
            const route = this.stack[routeCategoryIndex]
            if (this.stackNames[routeCategoryIndex].name === route.name) {
              this.stackNames[routeCategoryIndex].header = newCategory
            }
          }
        },
      },
      topic: {
        handler(newTopic, oldVal) {
          if (!newTopic) return
          const routeTopicIndex = this.stack.findIndex(route => this.isAllowedTopic(route))
          // We allow certain routes with previous breadcrumb to override the header name,
          // this for instance happens when directly clicking on a reading room article,
          // to display in the previous breadcrumb section the reading room Topic name
          if (routeTopicIndex !== -1) {
            const route = this.stack[routeTopicIndex]
            if (this.stackNames[routeTopicIndex].name === route.name) {
              this.stackNames[routeTopicIndex].header = newTopic || oldVal
            }
          }
        },
      },
    },
    data() {
      return {
        stack: [],
        stackNames: [],
        namesWithoutParent: ['Profile', 'Support'],
        searchResultsName: 'SearchResults',
        namesToIncludeSearch: ['SearchArticleView', 'ReadingRoomArticle'],
        namesWithoutNameRoute: ['ArticleView'],
        searchName: 'Search',
        allowedCategories: ['ReadingRoomCategory', 'GoalsPreviewGoal'],
        allowedTopics: ['ReadingRoomTopic'],
      }
    },
    methods: {
      capitalizeText(text) {
        return capitalize(text)
      },
      addRouteToStack(route, isSearchResultsParent = false) {
        const previousRoute = this.stack[this.stack.length - 1]
        const isFirstChild = route.meta.parent !== previousRoute?.name
        if (isFirstChild || !previousRoute || this.namesWithoutParent.includes(route.name)) {
          this.stack = []
          this.stackNames = []
          const { parent } = route.meta
          if (
            (this.namesToIncludeSearch.includes(route.name) && isSearchResultsParent)
            || route.name === 'SearchArticleView'
          ) {
          this.stack.push(null)
          this.stackNames.push({ name: this.searchName, header: this.searchName })
          } else if (parent && !this.namesWithoutParent.includes(route.name)) {
          this.addRouteToStack(this.getParentRoute(parent))
          }
        }
        this.stack.push(route)
        let name = route.header || route.meta.header || route.name
        if (this.namesWithoutNameRoute.includes(route.name)) {
          name = ''
        }
        this.stackNames.push({ name: route.name, header: name })
      },
      getParentRoute(parentName) {
        return this.$router.options.routes.find(route => route.name === parentName)
      },
      navigationClick(route) {
        if (!route) {
          this.$router.back()
          return
        }
        this.$router.push(route)
      },
      isAllowedCategory(route) {
        return this.allowedCategories.includes(route.name)
      },
      isAllowedTopic(route) {
        return this.allowedTopics.includes(route.name)
      },
    },
    computed: {
      ...mapState('route', ['title', 'category', 'topic']),
    },
  }
</script>

<style lang="scss">
.nhsuk-breadcrumb {
  background-color: transparent;
  width: 100%;
  .nhsuk-width-container {
    margin: 0;
    &.nhsuk-breadcrumb-container--is-center-text {
      display: flex;
      justify-content: center;
      margin: 0 auto;
    }
    .nhsuk-breadcrumb__list {
      display: flex;
      align-items: center;
      .nhsuk-breadcrumb__item {
        padding-right: 0;
        font-size: 1.5rem;
        color: color(_white);
        cursor: auto;
        display: flex;
        h1 {
          margin-bottom: 0;
          color: color(_white);
        }
        .nhsuk-breadcrumb__button {
          &:not(.nhsuk-breadcrumb__link) {
            cursor: auto;
            color: color(_white);
          }
          &.nhsuk-breadcrumb__link {
            color: color(_white);
            cursor: pointer;
            &:hover {
              color: color(_black);
            }
          }
        }
        &.nhsuk-breadcrumb__item--no-visible {
          display: none;
        }
        &:not(:last-child):after {
          content: '';
          display: inline-block;
          height: 20px;
          width: 20px;
          margin-bottom: 5px;
        }
        &::before {
          content: none;
        }
      }
    }
  }
}
</style>
